import {ApiRoles} from '@common/enum/apiRoles.enum';
import {BackofficeRoles} from '@common/enum/backofficeRoles.enum';
import {Roles} from '@common/enum/roles.enum';
import {AppCookies} from '@common/statics/cookieHandler';
import {setToken} from '@common/statics/hubspotToken';
import {SavedLoginData, ShopiagoActivateDTO} from '@common/ts/interfaces';

export function clearLoginData(): void {
  AppCookies.removeCookie('loginToken');
  AppCookies.removeCookie('refreshToken');
  AppCookies.removeCookie('loginTokenLastChecked');
  AppCookies.removeCookie('hubspotToken');
  setToken(null, null);
}

export function setLoginData({
  authorities, expires_in, access_token, companyModules, itemLocationModules,
  websocketUser, userExtraData, refresh_token,
}: ShopiagoActivateDTO, isImpersonate = false): void {
  AppCookies.setCookie('loginToken', JSON.stringify(<SavedLoginData>{
    expiresIn: expires_in * 1000,
    token: access_token,
    loginTime: new Date().getTime(),
    companyModules,
    itemLocationModules,
    roles: authorities.map(({authority}) => BackofficeRoles[authority as keyof typeof BackofficeRoles] ||
      ApiRoles[authority as keyof typeof ApiRoles] || Roles[authority as keyof typeof Roles]),
    websocketUser,
    isImpersonate,
    userId: userExtraData.id,
    authorities,
  }));
  AppCookies.setCookie('refreshToken', refresh_token);
}
