import {ChangeDetectionStrategy, Component} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AlertNotificationComponent} from '@common/components/alert-notification/alert-notification.component';
import {CookieNotificationComponent} from '@common/components/cookie-notification/cookie-notification.component';
import {CanDeactivateService} from '@common/services/can-deactivate/can-deactivate.service';
import {SignalrService} from '@common/services/signalr/signalr.service';
import {AppCookies} from '@common/statics/cookieHandler';
import {refreshCTA} from '@common/statics/hubspotToken';
import {sendEvent} from '@common/statics/tagManager';
import {AppCommon} from '@common/ts/appCommon';
import {Environment} from '@common/ts/environment';
import {HubspotTokenResponse} from '@common/ts/interfaces';
import {TradeURLs} from '@common/ts/tradeInterfaces';
import {untilDestroyed} from '@ngneat/until-destroy';
import {UserDataService} from '@trade/services/user-data/user-data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [
    CookieNotificationComponent,
    AlertNotificationComponent,
    RouterModule,
  ],
  providers: [SignalrService],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent extends AppCommon<TradeURLs> {

  public needAlert = Environment.getEnv() === 'rc';

  constructor(public activateSrv: CanDeactivateService<TradeURLs>,
              public signalR: SignalrService,
              public override userDataService: UserDataService) {
    super(userDataService);
  }

  override navigationEndHandler(url: string): void {
    const hubspotObject = AppCookies.getCookie<HubspotTokenResponse>('hubspotToken');
    refreshCTA(url, hubspotObject?.email);
    sendEvent({event: 'page_view'});
    if (!this.signalR.hasConnection()) {
      this.signalR.connectCall('userDataChange', () => {
        this.userDataService.refreshUserData().pipe(untilDestroyed(this)).subscribe();
      });
    }
  }

}
