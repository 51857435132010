import {HubspotTokenResponse} from '@common/ts/interfaces';

export function setToken(url: string, tokenData: HubspotTokenResponse): void {
  if (window.HubSpotConversations && window.hsConversationsSettings?.identificationToken !== tokenData?.visitorAccessToken) {
    window.HubSpotConversations.clear({resetWidget: true});
    window.hsConversationsSettings = {
      loadImmediately: false,
      identificationEmail: tokenData?.email || '',
      identificationToken: tokenData?.visitorAccessToken || '',
    };
    window.HubSpotConversations.widget.load();
    refreshCTA(url, tokenData?.email);
  }
}

export function refreshCTA(url: string, email: string): void {
  const _hsq = window._hsq = window._hsq || [];
  if (email) {
    _hsq.push([
      'identify',
      {
        email,
      }]);
  }
  if (url) {
    _hsq.push(['setPath', url]);
    _hsq.push(['trackPageView']);
  }
  if (window.HubSpotCallsToActions) {
    window.HubSpotCallsToActions.refresh();
  } else {
    window.hsCallsToActionsReady = [
      (): void => {
        window.HubSpotCallsToActions.refresh();
      },
    ];
  }
}
